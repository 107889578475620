import React from "react";

import NvidiaImage from "../assets/media/business_standards.svg";
import HumanImage from "../assets/media/business.png";
import NearImage from "../assets/media/financial.svg";
import ConcordiumImage from "../assets/media/yahoo.png";
import NBCNewsImage from "../assets/media/nbc_news.png";

import styles from "../styles/team.module.css";

export default function OurTeamComponents() {
  return (
    <section className={styles["our__team_section"]}>
      <div className={styles["container"]}>
        <div className={styles["our__team_container"]}>
          <h1>Our Team</h1>
          <p>
          CCX is led by a team with a combined experience of 10+ years across some of the top firms in the world such as Goldman Sachs and Oracle with a proven track record of building, scaling and successful acquisition as entrepreneurs in the areas of banking, finance and blockchain technology.
            <br />
            <br />
            The founding team is supported by a core team of advisors consisting of innovation having over three decades of experience from institutions such as London School of Economics (LSE) and Cornell University as well as major financial services providers and government bodies.

          </p>
          {/* <div className={styles["team__details"]}>
            <div className={styles["team__container"]}>
              <div className={styles["team__content"]}>
                <div className={styles["profile__photo"]}></div>
                <h1>Aakarsh Naidu</h1>
                <h6>Helo</h6>
                <p>Helo</p>
              </div>
              <div className={styles["team__content"]}>
                <div className={styles["profile__photo"]}></div>
                <h1>Aakarsh Naidu</h1>
                <h6>Helo</h6>
                <p>Helo</p>
              </div>
            </div>
          </div> */}
          <div className={styles["featured__media"]}>
            <h1>Team previously Featured In Media</h1>
            <div className={styles["support__logos"]}>
              <div className={styles["support__logo"]}>
                <img src={NvidiaImage} alt="nvidia"  height="60%"/>
              </div>
              <div className={styles["support__logo"]}>
                <img src={NearImage} alt="near" height="100%" />
              </div>
              <div className={styles["support__logo"]}>
                <img src={ConcordiumImage} alt="concordium"  height="60%" />
              </div>
              <div className={styles["support__logo"]}>
                <img src={NBCNewsImage} alt="nbcNews"  height="60%"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
