import React from "react";

import SecureImage from "../assets/images/image.png";

import styles from "../styles/secure.module.css";

export default function SecureComponent() {
  return (
    <section className={styles["secure__section"]}>
      <div className={styles["container"]}>
        <div className={styles["secure__content"]}>
          <div className={styles["flex__content"]}>
            <div className={styles["flex__left_content"]}>
              <img src={SecureImage} alt="secure__image" />
            </div>
            <div className={styles["flex__right_content"]}>
                <h1>Our Mission</h1>
                <p>At CCX, our mission is to empower businesses of all sizes to thrive in the global marketplace by providing them with a robust, digital currency powered cross-border payments platform that simplifies transactions, reduces costs and enhances security.</p>
                {/* <button>Read More</button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
