import React, { useState } from "react";

import styles from "../styles/navbar.module.css";
import { Menu } from "lucide-react";
import { JOIN_WISHLIST_LINK } from "../Constants";

export default function NavbarComponent() {
  const [dropDown, showDropDown] = useState(false);
  const [height, setHeight] = useState("10vh");

  const onButtonClick = () => {
    window.open(JOIN_WISHLIST_LINK, "__blank");
  };

  return (
    <div className={styles["navbar__container"]}>
      <nav>
        <div className={styles["navbar__content_container"]}>
          <div className={styles["logo__container"]}>
            <h1>.CCX.</h1>
          </div>
          <div className={styles["menu__container"]} id={styles["desktop"]}>
            <ul>
              <li>
                <a href="#who-we-are">Who We Are</a>
              </li>
              <li>
                <a href="#why-cbdcx">Why CCX</a>
              </li>
              <li>
                <a href="#who-can-benefit">Who Can Benefit</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className={styles["navbar__actions"]} id={styles["desktop"]}>
          <button
            className={styles["navbar__get_started"]}
            onClick={onButtonClick}
          >
            Get Started
          </button>
        </div> */}
        <div
          className={styles["navbar__actions"]}
          id={styles["mobile"]}
          onClick={() => showDropDown(!dropDown)}
        >
          <Menu />
        </div>
      </nav>
      {dropDown && (
        <div className={styles["drop__down_values"]}>
          <ul>
            <li>
              <a href="#who-we-are">Who We Are</a>
            </li>
            <li>
              <a href="#why-cbdcx">Why CBDCX</a>
            </li>
            <li>
              <a href="#who-can-benefit">Who Can Benefit</a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
